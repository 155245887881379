import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/Layout';
import ResourcesTabs from 'components/ResourcesTabs';

export default function ResourcesPage({ location, pageContext: {
  common,
  resources,
  links,
  navigation,
  tag,
  docsLink,
} }) {
  return (
    <>
    <Helmet>
      <meta property='og:image' content='/seo/preview.jpg' />
      <meta property='og:image:width' content={820} />
      <meta property='og:image:height' content={540} />
    </Helmet>
    <Layout
      page="resources"
      links={links}
      navigation={navigation}
      tag={tag}
      metaTitle='Resources | Reach Blockchain Development Platform'
      metaDescription='Gain fast access to valuable resources for learning blockchain development.'
    >
      <ResourcesTabs
        links={links}
        tag={tag}
        common={common}
        resources={resources}
        docsLink={docsLink + (location.state?.page ?? '')}
        tab={location.state?.tab ?? 2}
        query={location.state?.query}
      />
    </Layout>
    </>
  );
}
