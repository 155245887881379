import React from 'react';

import './VideoItem.scss';

function VideoItem({ url, image, title }) {
  return (
    <div className="get-started__item">
      <a
        href={url}
        className="get-started__item-image"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={image} alt={title} />
      </a>
      <a
        className="get-started__item-title"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {title}
      </a>
    </div>
  );
}

export default React.memo(VideoItem);
