import React from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { Tabs, Tab } from '@material-ui/core';
import Leaders from 'components/Leaders';
import FeaturedContent from 'components/FeaturedContent';
import GetStarted from 'components/GetStarted';
import FindOutMore from 'components/FindOutMore';

import './ResourcesTabs.scss';

function ResourcesTabs({ links, tag, common, resources, docsLink, tab }) {
  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      window.open(docsLink, '_blank');
    } else {
      navigate('', {
        state: {
          tab: newValue
        }
      })
    }
  };

  const { strapiResourcesPage } = useStaticQuery(graphql`{
    strapiResourcesPage {
      leaders {
        photo {
          childImageSharp {
            fixed(width: 120, height: 120) {
              src
            }
          }
        }
      }
      community_contents {
        preview {
          childImageSharp {
            fixed(width: 281, height: 142) {
              src
            }
          }
        }
      }
    }
  }`)

  return (
    <section className="resources-tabs">
      <div className="container container--narrow">
        <Tabs value={tab} onChange={handleChange}>
          <Tab label={resources.documentation_tab} />
          <Tab label={resources.video_tab} />
          <Tab label={resources.community_tab} />
        </Tabs>
      </div>
      {tab === 1 && (
        <>
          <GetStarted
            page="resources"
            title={resources.get_started_videos_text}
            videos={resources.get_started_videos}
          />
          <FindOutMore
            page="resources"
            title={resources.find_out_more_videos_text}
            youtubeButton={resources.go_to_youtube_button}
            youtubeLink={links.youtube}
          />
        </>
      )}
      {tab === 2 && (
        <>
          <Leaders
            page="resources"
            discord={links.discord}
            common={common}
            leaders={resources.leaders}
            images={strapiResourcesPage.leaders}
            buttonColor="red"
          />
          <FeaturedContent
            page="resources"
            tag={tag}
            common={common}
            contents={resources.community_contents}
            images={strapiResourcesPage.community_contents}
            buttonText={resources.load_more_button}
          />
        </>
      )}
    </section>
  );
}

export default React.memo(ResourcesTabs);