import React from 'react';

import './FindOutMore.scss';

function FindOutMore({ page, title, youtubeButton, youtubeLink }) {
  const sectionClass = `find-more${(page || '') && ' find-more--' + page}`;

  return (
    <section className={sectionClass}>
      <div className="container container--narrow">
        <div className="find-more__inner">
          <p className="find-more__title">
            {title}
          </p>
          <div className="find-more__link-wrapper">
            <a
              href={youtubeLink}
              className="find-more__link button button--red"
              target="_blank"
              rel="noopener noreferrer"
            >
              {youtubeButton}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default React.memo(FindOutMore);
