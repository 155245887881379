import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Slider from "react-slick";
import VideoItem from 'components/VideoItem';

import './GetStarted.scss';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

function GetStarted({ page, title, videos }) {
  const sectionClass = `get-started${(page || '') && ' get-started--' + page}`;

  const { strapiResourcesPage } = useStaticQuery(graphql`{
    strapiResourcesPage {
      get_started_videos {
        preview {
          childImageSharp {
            fixed(width: 300, height: 169) {
              src
            }
          }
        }
      }
    }
  }`);

  const images = strapiResourcesPage.get_started_videos
    .map(image => image.preview.childImageSharp.fixed.src);

  const videoItems = videos.map((video, index) => (
    <VideoItem
      url={video.link}
      title={video.title}
      image={images[index]}
      key={index}
    />
  ));

  const sliderSettings = {
    className: 'center',
    centerMode: true,
    centerPadding: '16px',
    infinite: true,
    arrows: false
  };

  return (
    <section className={sectionClass}>
      <div className="get-started__container container container--narrow">
        <p className="get-started__title section-title">
          {title}
        </p>
        <div className="get-started__items">
          {videoItems}
        </div>
        <div className="get-started__slider">
          <Slider {...sliderSettings}>
            {videos.map((video, index) => (
              <VideoItem
                url={video.link}
                title={video.title}
                image={images[index]}
                key={index}
              />
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default React.memo(GetStarted);
